const getDate = () => {
  const today = new Date()
  const weekday = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  ]
  const month = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'Sept',
    'Okt',
    'Nov',
    'Dez'
  ]

  const result = `${weekday[today.getDay()]}, ${today.getDate()}. ${
    month[today.getMonth()]
  } ${today.getFullYear()}`

  if (!window || window.innerWidth > 1024) return [result]
  return result.split(',')
}

export default getDate
