import React, { useEffect, useState, useContext, FC, useMemo } from 'react'
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '@/containers/Layout'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import '@/styles/curator.css'
import QuestionIcon from '@/assets/icons/MarktQuestion.inline.svg'
import styled from 'styled-components'
import getDate from '@/hooks/getDate'
import { mobileMediaQuery, desktopMediaQuery } from '@/styles/MUItheme'
import { useWindowDimensions } from '@/hooks/useWindow'
import { SanityPage } from 'web/graphql-types'
import { NavigationContext } from '@/contexts/NavigationContext'
import { getUri } from '@/utils/routing'

const widthDict = {
  xs: '10rem',
  sm: '13rem',
  md: '15rem'
}

const IconBox = styled(Box)`
  ${mobileMediaQuery} {
    margin-bottom: 4rem;
    height: 7.5rem;
    width: 7.5rem;
    margin-left: 10px;
    position: unset;
  }

  svg {
    transition: transform linear 0.2s;
    transform: rotate(360deg);
    :hover {
      transform: rotate(180deg);
    }
    height: 7.5rem;
    width: 7.5rem;
    ${desktopMediaQuery} {
      :hover {
        cursor: pointer;
      }
    }
  }
`
interface Props {
  data: {
    sanityPage: SanityPage
  }
}

const Markt: FC<Props> = props => {
  const [date, setDate] = useState([''])
  const { width: windowWidth } = useWindowDimensions()

  const { backButton, route, meta } = props?.data?.sanityPage || {
    backButton: false,
    route: '',
    meta: undefined
  }
  const { setRoute, setBackButton } = useContext(NavigationContext)

  useEffect(() => {
    setBackButton(backButton || false)
    setRoute(getUri(route?.slug?.current, route?._type || undefined))
  }, [])

  useEffect(() => {
    setDate(() => getDate())
  }, [setDate])

  useEffect(() => {
    setDate(() => getDate())
  }, [windowWidth, setDate])

  const width = useMemo(() => {
    if (!windowWidth || windowWidth > 1600) return 'md'
    else if (windowWidth > 1023) return 'sm'
    else return 'xs'
  }, [windowWidth])

  return (
    <Layout meta={meta}>
      <Helmet>
        <script src={'/curator.js'} type="text/javascript" />
      </Helmet>
      <Container maxWidth="xl" disableGutters>
        <Box pl={2} pr={3}>
          <Grid container direction="column">
            <Box
              pb={{ xs: 0, sm: 2 }}
              pt={{ xs: 3, sm: 5 }}
              pr={{ xs: 0, sm: 17 }}
              pl={{ xs: 0, sm: 2 }}
              mr={'-10px'}
            >
              <Box mx={'10px'} mb={{ xs: 3, sm: 8 }}>
                <Typography variant="h2" component="h1">Marktplatz</Typography>
                {date.map(d => (
                  <Typography key={d} variant="h2">
                    {d}
                  </Typography>
                ))}
              </Box>

              <IconBox
                m={3}
                position="fixed"
                top={widthDict[width] || '15rem'}
                right="0"
                width={widthDict[width] || '15rem'}
                height={widthDict[width] || '15rem'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => navigate('/spielregeln')}
              >
                <QuestionIcon />
              </IconBox>

              <div id="curator-feed-default-feed-layout">
                <a
                  href="https://curator.io"
                  target="_blank"
                  className="crt-logo crt-tag"
                  rel="noreferrer"
                >
                  Powered by Curator.io
                </a>
              </div>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Layout>
  )
}

export default Markt

export const query = graphql`
  query {
    sanityPage(title: { eq: "Markt" }) {
      id
      title
      meta {
        ...metaData
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
